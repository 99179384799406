import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useEffect } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';
import {
  isCountryConfigLoadedSelector,
  isUserLoggedInSelector,
  shouldRedirectToFirstPaymentCheckoutSelector,
} from '../selectors';
import { REFERRER_URL_SAVED } from '../events';
import { ROUTE_TYPE, RouteProvider } from './context';
import TrackPageView from '../../partials/Tracking/TrackPageView';
import TermsAndConditionsDialog from '../../partials/TermsAndConditionsDialog/TermsAndConditionsDialog';
import { isLoadingPublisherSelector } from '../../selectors/publisher';
import RouteWithInterstitial from './RouteWithInterstitial';
import HubspotWidget from '../../partials/Tracking/HubspotWidget';
import { CHECK_HAS_FIRST_PAYMENT_PENDING, CHECK_IS_LOGGED_IN } from './events';
import { useScrollReset } from '../../hooks/useScrollReset';

function PrivateRoute({ as: Component, disableInterstitial, ...rest }) {
  const { pageViewName, pageViewPayloadSelector } = rest;
  const params = useParams();

  const isLogged = useSelector(isUserLoggedInSelector);
  const shouldRedirectToFirstPaymentCheckout = useSelector(
    shouldRedirectToFirstPaymentCheckoutSelector
  );

  const isLoading = useSelector(isLoadingPublisherSelector);
  const isConfigLoaded = useSelector(isCountryConfigLoadedSelector);
  const isUploadCSV = useMatch('/upload-csv');
  const location = useLocation();
  useScrollReset(location);
  useMount(() => dispatch(REFERRER_URL_SAVED));
  useEffect(() => dispatch(CHECK_IS_LOGGED_IN), [isLogged]);
  useEffect(
    () => dispatch(CHECK_HAS_FIRST_PAYMENT_PENDING),
    [shouldRedirectToFirstPaymentCheckout]
  );

  if (
    !isLogged ||
    isLoading ||
    !isConfigLoaded ||
    shouldRedirectToFirstPaymentCheckout
  )
    return null;

  return (
    <RouteProvider routeType={ROUTE_TYPE.PRIVATE}>
      <RouteWithInterstitial disable={disableInterstitial}>
        <TermsAndConditionsDialog />
        <TrackPageView
          pageViewName={pageViewName}
          payload={
            pageViewPayloadSelector &&
            pageViewPayloadSelector({ isLogged, ...rest, ...params })
          }
        >
          <Component {...rest} />
        </TrackPageView>
        {!isUploadCSV && <HubspotWidget />}
      </RouteWithInterstitial>
    </RouteProvider>
  );
}

export default PrivateRoute;
