import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import BackLink from 'design-system/components/BackLink';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Link } from 'react-router-dom';
import SubscriptionLayout from './partials/Layout/Layout';
import { SUBSCRIPTION } from '../../constants/routes';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Header from './partials/Layout/Header';
import CreditCardForm from './partials/CreditCardForm/CreditCardForm';

const Content = styled.div`
  display: grid;
  grid-column: 3 / span 8;
  ${BackLink} {
    ${spacing.stack(SPACING_SIZE.L, false)}
    text-decoration: none;
  }
`;

export default function CreditCardDetails() {
  return (
    <SubscriptionLayout>
      <Layout.Padded>
        <Grid>
          <Content>
            <BackLink forwardedAs={Link} to={SUBSCRIPTION}>
              <FormattedMessageWithValidation id="subscription_back_to_subscription" />
            </BackLink>
            <Header>
              <FormattedMessageWithValidation id="subscription_credit_card_details_add_card_header" />
            </Header>
            <CreditCardForm />
          </Content>
        </Grid>
      </Layout.Padded>
    </SubscriptionLayout>
  );
}
