import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { navigateTo } from '../../../effects/routing';
import { PAYMENT_STATUS } from './constants';

export const CHECKOUT_FIRST_PAYMENT_PROCESSING_PAGE_MOUNTED = `CHECKOUT_FIRST_PAYMENT_PROCESSING_PAGE_MOUNTED`;
export const CHECKOUT_PAYMENT_CHECK_STATUS = `CHECKOUT_PAYMENT_CHECK_STATUS`;
export const CHECKOUT_PAYMENT_CHECK_STATUS_RECEIVED = `CHECKOUT_PAYMENT_CHECK_STATUS_RECEIVED`;

registerEventHandler(
  CHECKOUT_FIRST_PAYMENT_PROCESSING_PAGE_MOUNTED,
  (_, { subscriptionChangeRequestId, successUrl, failureUrl }) => ({
    ...effects.dispatchLater({
      id: CHECKOUT_PAYMENT_CHECK_STATUS,
      milliseconds: 3000,
      payload: {
        subscriptionChangeRequestId,
        successUrl,
        failureUrl,
      },
    }),
  })
);

registerEventHandler(
  CHECKOUT_PAYMENT_CHECK_STATUS,
  (
    { environment: { apiUrl } },
    { successUrl, failureUrl, subscriptionChangeRequestId }
  ) =>
    http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/payment-status`,
      successEvent: {
        id: CHECKOUT_PAYMENT_CHECK_STATUS_RECEIVED,
        payload: {
          subscriptionChangeRequestId,
          successUrl,
          failureUrl,
        },
      },
    }),
  [environment()]
);

registerEventHandler(
  CHECKOUT_PAYMENT_CHECK_STATUS_RECEIVED,
  (
    _,
    [
      {
        data: { status },
      },
      { subscriptionChangeRequestId, successUrl, failureUrl },
    ]
  ) => {
    if (status === PAYMENT_STATUS.SUCCESS) {
      return {
        ...navigateTo(successUrl),
      };
    }

    if (status === PAYMENT_STATUS.FAILED) {
      return navigateTo(failureUrl);
    }

    return effects.dispatchLater({
      id: CHECKOUT_PAYMENT_CHECK_STATUS,
      payload: { subscriptionChangeRequestId, successUrl, failureUrl },
      milliseconds: 1000,
    });
  }
);
