import styled from 'styled-components';
import { useMount } from 'react-use';
import { useSelector } from 'reffects-store';
import { dispatch, dispatchLater } from 'reffects';
import { Link, useMatch, useParams } from 'react-router-dom';
import Layout from 'design-system/components/Layout';
import Anchor from 'design-system/components/Anchor';
import { OutlineButton } from 'design-system/components/Button/presets';
import Illustration from 'design-system/components/Illustration/Illustration';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { Navigation } from '../../../partials';
import Sidebar from '../partials/Sidebar';
import {
  CHECKOUT_STEPS,
  CURRENT_STEP_STATUS,
} from '../partials/CheckoutStepper/CheckoutStepper';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { CHECKOUT_PUBLISHER_DATA_REQUESTED } from '../events';
import { isCountryConfigLoadedSelector } from '../../../App/selectors';
import { continueLinkSelector, segmentUserDataSelector } from './selectors';
import Pending from './partials/Pending';
import { checkoutPaymentMethodRoute } from '../../../utils/proppitWebRouter';
import {
  checkoutSubRouteSelector,
  checkoutTranslationTagSelector,
} from '../configuration/selectors';
import { TRANSLATION_NAMES } from '../configuration/constants';
import {
  checkoutPageSCRIdSelector,
  isSelfServicePublisherSelector,
  selfServicePublisherEmailSelector,
} from '../selectors';
import { TRACK_CLICK } from '../../../partials/Tracking/events';
import PublisherData from '../PublisherData/PublisherData';
import { CHECKOUT_REDIRECT_AFTER_SUCCESS } from './partials/events';

const MILLISECONDS_UNTIL_REDIRECT_TO_OVERVIEW = 5000;

const Title = styled(Subtitle1)`
  display: block;
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Body = styled(Body1)`
  display: block;
  ${spacing.stack(SPACING_SIZE.XL)}
`;

const WelcomeIllustration = styled(Illustration)`
  margin: auto;
  ${spacing.stack(SPACING_SIZE.L)}
`;

function Success({ link, isSelfServicePublisher, email }) {
  const isFirstPayment = useMatch('/checkout/first-payment/*');
  const confirmationCaptionTitleTag = useSelector((state) =>
    checkoutTranslationTagSelector(
      state,
      isSelfServicePublisher
        ? TRANSLATION_NAMES.CONFIRMATION_TITLE_SELF_SERVICE
        : TRANSLATION_NAMES.CONFIRMATION_TITLE
    )
  );
  const confirmationCaptionTag = useSelector((state) =>
    checkoutTranslationTagSelector(
      state,
      isSelfServicePublisher
        ? TRANSLATION_NAMES.CONFIRMATION_CAPTION_SELF_SERVICE
        : TRANSLATION_NAMES.CONFIRMATION_CAPTION
    )
  );

  return (
    <>
      <Title>
        <FormattedMessageWithValidation id={confirmationCaptionTitleTag} />
      </Title>
      <WelcomeIllustration name="welcome" />
      <Body1>
        <FormattedMessageWithValidation
          id={confirmationCaptionTag}
          values={{ email }}
        />
      </Body1>
      <br />
      {isSelfServicePublisher && isFirstPayment ? (
        <Body1>
          <FormattedMessageWithValidation id="checkout_confirmation_caption_2_self_service" />
        </Body1>
      ) : (
        <Anchor $weight={FONT_WEIGHT.BOLD} href={link}>
          <FormattedMessageWithValidation id="checkout_confirmation_continue_button" />
        </Anchor>
      )}
    </>
  );
}

function Failed({ subscriptionChangeRequestId }) {
  const subRoute = useSelector(checkoutSubRouteSelector);
  return (
    <>
      <Title>
        <FormattedMessageWithValidation id="checkout_completed_failed_title" />
      </Title>
      <Body>
        <FormattedMessageWithValidation id="checkout_completed_failed_body" />
      </Body>
      <OutlineButton
        forwardedAs={Link}
        to={checkoutPaymentMethodRoute({
          subscriptionChangeRequestId,
          subRoute,
        })}
      >
        <FormattedMessageWithValidation id="checkout_completed_failed_btn_back" />
      </OutlineButton>
    </>
  );
}

export default function Confirmation() {
  const link = useSelector(continueLinkSelector);
  const subscriptionChangeRequestId = useSelector(checkoutPageSCRIdSelector);
  const { status } = useParams();
  const isConfigLoaded = useSelector(isCountryConfigLoadedSelector);
  const userData = useSelector(segmentUserDataSelector);
  const isSelfServicePublisher = useSelector(isSelfServicePublisherSelector);
  const email = useSelector(selfServicePublisherEmailSelector);
  useMount(() => {
    if (!isConfigLoaded) {
      dispatch({
        id: CHECKOUT_PUBLISHER_DATA_REQUESTED,
        payload: { subscriptionChangeRequestId },
      });
    }
    if (status === CURRENT_STEP_STATUS.SUCCESS) {
      dispatchLater({
        id: CHECKOUT_REDIRECT_AFTER_SUCCESS,
        payload: { to: link },
        milliseconds: MILLISECONDS_UNTIL_REDIRECT_TO_OVERVIEW,
      });
    }
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: 'logged-user-data',
        sectionName: 'checkout-confirmation',
        payload: { ...userData },
      },
    });
  });

  if (!isConfigLoaded) return null;

  return (
    <Layout independentScroll>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.LeftSidebar>
        <Sidebar
          currentStep={CHECKOUT_STEPS.CONFIRMATION}
          currentStepStatus={status}
        />
      </Layout.LeftSidebar>
      <Layout.Content>
        <Layout.Padded>
          {status === CURRENT_STEP_STATUS.SUCCESS && (
            <Success
              link={link}
              isSelfServicePublisher={isSelfServicePublisher}
              email={email}
            />
          )}
          {status === CURRENT_STEP_STATUS.PENDING && (
            <Pending
              subscriptionChangeRequestId={subscriptionChangeRequestId}
            />
          )}
          {status === CURRENT_STEP_STATUS.FAILED && (
            <Failed subscriptionChangeRequestId={subscriptionChangeRequestId} />
          )}
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}
