import { createSelector } from 'reselect';
import {
  dotPropertyOperatesIn,
  isLATAM,
  isMexico,
  isSEA,
  isSpain,
} from '../utils/countries';
import {
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
  DIRECT_DEBIT,
} from '../constants/paymentMethod';
import { localeSelector, oneOf } from './user';
import { SUBSCRIPTION_TYPES } from '../constants/subscription';

// Publisher

export function publisherSelector(state) {
  return state.publisher;
}

export function isLoadingPublisherSelector(state) {
  const publisher = publisherSelector(state);
  return Boolean(publisher == null || publisher.loading);
}

export const isDeveloperSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.isDeveloper ?? false
);

export const isAgencySelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.isDeveloper === false ?? false
);

export const publisherIdSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.id
);

export const publisherNameSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.name ?? ''
);

export const publisherNameByUserLocaleSelector = createSelector(
  localeSelector,
  publisherMultiLangNameSelector,
  (userLocale, names) => {
    const localizedValue = (names || []).find(
      (name) => userLocale === name.locale
    );

    if (localizedValue) {
      return localizedValue.text;
    }

    return names?.[0]?.text ?? '';
  }
);

export const publisherLogoSelector = createSelector(
  publisherSelector,
  (publisher) => {
    if (!publisher?.logo) {
      return null;
    }

    if (typeof publisher.logo === 'object') {
      return publisher.logo;
    }

    return publisher.logoVersion
      ? `${publisher.logo}?v=${publisher.logoVersion}`
      : publisher.logo;
  }
);

export const publisherPublishOnThailandPropertySelector = createSelector(
  publisherSelector,
  (publisher) => {
    const { publishesOnThailandProperty = false } = publisher || {};
    return publishesOnThailandProperty;
  }
);

export const publisherBannedBrandsForFreeListingsSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.bannedBrandsForFreeListings ?? []
);

export const publisherDescriptionSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.description ?? null
);

export const publisherBackgroundImageSelector = createSelector(
  publisherSelector,
  (publisher) => {
    if (!publisher?.backgroundImage) {
      return null;
    }

    if (typeof publisher.backgroundImage === 'object') {
      return publisher.backgroundImage;
    }

    return publisher.backgroundImageVersion
      ? `${publisher.backgroundImage}?v=${publisher.backgroundImageVersion}`
      : publisher.backgroundImage;
  }
);

export const publisherWebsiteUrlSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.url ?? null
);

export const publisherAddressSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.address ?? null
);

export const publisherCountrySelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.countryCode
);

export const publisherCountryToLowerCaseSelector = createSelector(
  publisherCountrySelector,
  (countryCode) => countryCode?.toLowerCase() ?? null
);

export function publisherCountryShowRenewalPricesSelector(state) {
  const countryCode = publisherCountrySelector(state);
  return !['MX'].includes(countryCode);
}

export function publisherMultiLangNameSelector(state) {
  return state.publisher?.names;
}

export function publisherMultiLangAddressSelector(state) {
  return state.publisher?.addresses;
}

export function publisherMultiLangDescriptionSelector(state) {
  return state.publisher?.descriptions;
}

export function isPublisherFromCountrySelector(state, country) {
  const publisherCountry = publisherCountrySelector(state);
  return publisherCountry === country;
}

export function hasBoostingEnabledSelector(state) {
  return !isPublisherFromMxSelector(state);
}

export function hasSuperboostSubscriptionSelector(state) {
  return Boolean(publisherSelector(state)?.hasSuperboostSubscription);
}

// Subscription

export const activeSubscriptionSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.subscription
);

export function publisherSubscriptionIdSelector(state) {
  return publisherSelector(state)?.subscriptionId;
}

export const isPublisherFromSEASelector = createSelector(
  publisherCountrySelector,
  isSEA
);

export const isPublisherFromLATAMSelector = createSelector(
  publisherCountrySelector,
  isLATAM
);

export const isPublisherFromMexicoSelector = createSelector(
  publisherCountrySelector,
  isMexico
);

export const isRenewDisabledSelector = oneOf(
  isPublisherFromMexicoSelector,
  (state) => publisherCountrySelector(state) === 'TH'
);

export const isPublisherFromSpainSelector = createSelector(
  publisherCountrySelector,
  isSpain
);

export const isPublisherForTestingSelector = (state) =>
  state['publisher:isForTesting'] ?? false;

export const showByDotPropertyLogoSelector = createSelector(
  publisherCountrySelector,
  dotPropertyOperatesIn
);

export function showByLamudiLogoSelector(state) {
  return (
    isMexico(publisherCountrySelector(state)) || userFromLamudiSelector(state)
  );
}

export function userFromLamudiSelector(state) {
  return Boolean(state.userFromLamudi);
}

export function subscriptionToUpgradeSelector(state) {
  return state.subscriptionToUpgrade;
}

export const publisherSubscriptionPlanTypeSelector = createSelector(
  activeSubscriptionSelector,
  (activeSubscription) => activeSubscription?.type ?? null
);

export const publisherSubscriptionPlanIsFreeSelector = createSelector(
  publisherSubscriptionPlanTypeSelector,
  (subscriptionType) => subscriptionType === 'free'
);

export const publisherSubscriptionPlanIsPaidSelector = createSelector(
  publisherSubscriptionPlanTypeSelector,
  (subscriptionType) => subscriptionType === 'paid'
);

export const publisherHasSuperboostSubscriptionSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.hasSuperboostSubscription ?? false
);

export const startDateSelector = createSelector(
  activeSubscriptionSelector,
  (activeSubscription) => {
    const startDate = activeSubscription?.startDate;
    return startDate ? new Date(startDate) : null;
  }
);

export const nextBillingDateSelector = createSelector(
  activeSubscriptionSelector,
  (activeSubscription) => {
    const nextBillingDate = activeSubscription?.nextBillingDate;
    return nextBillingDate ? new Date(nextBillingDate) : null;
  }
);

function millisecondsToDays(milliseconds) {
  return Math.ceil(milliseconds / 1000 / 60 / 60 / 24);
}

export const paymentMethodSelector = createSelector(
  activeSubscriptionSelector,
  (subscription) => subscription?.paymentMethod
);

export const publisherPublishesAdsManuallySelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.publishAdsManually ?? false
);

export const paymentMethodIsCreditCardSelector = createSelector(
  paymentMethodSelector,
  (paymentMethod) => paymentMethod === CREDIT_CARD
);

export const paymentMethodIsBankTransferSelector = createSelector(
  paymentMethodSelector,
  (paymentMethod) => paymentMethod === BANK_TRANSFER
);

export const paymentMethodIsCashSelector = createSelector(
  paymentMethodSelector,
  (paymentMethod) => paymentMethod === CASH
);

export const paymentMethodIsDirectDebitSelector = createSelector(
  paymentMethodSelector,
  (paymentMethod) => paymentMethod === DIRECT_DEBIT
);

export const developerActivePromotedProjectsSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.activePromotedProjects
);

export function publisherTaxDiscountsSelector(state) {
  if (!isPublisherFromCountrySelector(state, 'CO')) {
    return undefined;
  }

  const taxDiscounts = state.publisher?.taxDiscounts;
  return {
    reteIcaCO: Boolean(taxDiscounts?.reteIcaCO),
    reteIvaCO: Boolean(taxDiscounts?.reteIvaCO),
    retefuenteCO: Boolean(taxDiscounts?.retefuenteCO),
  };
}

export const subscriptionChangeRequestSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.pendingSubscriptionChangeRequest
);

export const subscriptionChangeRequestIdSelector = createSelector(
  subscriptionChangeRequestSelector,
  (scr) => scr?.id
);

const subscriptionChangeRequestProductsSelector = createSelector(
  subscriptionChangeRequestSelector,
  (scr) => scr?.products ?? []
);

const subscriptionChangeRequestHasProduct = (productType) =>
  createSelector(subscriptionChangeRequestProductsSelector, (products) =>
    products.some(({ type }) => type === productType)
  );

export const hasSubscriptionChangeRequestForBaseSubscriptionSelector =
  subscriptionChangeRequestHasProduct(SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION);

export const hasSubscriptionChangeRequestForSuperboostSelector =
  subscriptionChangeRequestHasProduct(SUBSCRIPTION_TYPES.SUPERBOOST);

export const subscriptionChangeRequestProductForBaseSubscriptionSelector =
  productToSubscriptionLike(SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION);

export const subscriptionChangeRequestProductForSuperboostSelector =
  productToSubscriptionLike(SUBSCRIPTION_TYPES.SUPERBOOST);

function productToSubscriptionLike(productType) {
  return (state) => {
    const products = subscriptionChangeRequestProductsSelector(state);
    const product = products.find(({ type }) => type === productType);
    if (!product) {
      return undefined;
    }
    const subscriptionChangeRequest = subscriptionChangeRequestSelector(state);
    return {
      products: [product],
      billingCycleInMonths: subscriptionChangeRequest.billingCycleInMonths,
      totalBasePriceWithDiscount: product.basePriceWithDiscounts,
      discounts: product.discounts,
      taxApplied: product.taxApplied,
      taxDiscounts: product.retentions,
      totalPrice: product.totalPrice,
    };
  };
}

export const superboostSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.superboost
);

export const nextBillingDateSuperboostSelector = createSelector(
  superboostSelector,
  (activeSuperboostSubscription) => {
    const nextBillingDate = activeSuperboostSubscription?.nextBillingDate;
    return nextBillingDate ? new Date(nextBillingDate) : null;
  }
);

export const daysToExpireSuperboostSelector = createSelector(
  nextBillingDateSuperboostSelector,
  (_, today) => today,
  (nextBillingDate, today) => {
    if (!nextBillingDate) {
      return null;
    }
    const diff = nextBillingDate - today;
    return millisecondsToDays(diff);
  }
);

export function isPublisherFromMxSelector(state) {
  const countryCode = publisherCountrySelector(state);
  return countryCode === 'MX';
}

export function changedBillingCycleInTHSelector(state) {
  return Boolean(publisherSelector(state)?.changedBillingCycleInTH);
}

export function getPublisherMaxBoostablePropertiesFromSubscriptionSelector(
  state
) {
  const publisherSubscription = activeSubscriptionSelector(state);
  return publisherSubscription?.maxBoostableProperties;
}

export function getPublisherIsRematesSelector(state) {
  return publisherSelector(state)?.typeOfClient === 'remates';
}
