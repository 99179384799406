import { useSelector } from 'reffects-store';
import { isSelfServicePublisherSelector } from './selectors';
import { useScript } from '../../hooks/useScript';

function WithHotJar({ children, id, version }) {
  useScript(`https://static.hotjar.com/c/hotjar-${id}.js?sv=${version}`, {
    removeOnUnmount: true,
  });
  return children;
}

export function CheckSelfService({ children }) {
  const isSelfService = useSelector(isSelfServicePublisherSelector);
  // eslint-disable-next-line no-underscore-dangle
  const { hjid, hjsv } = window._hjSettings;
  return isSelfService && hjid ? (
    <WithHotJar id={hjid} version={hjsv}>
      {children}
    </WithHotJar>
  ) : (
    children
  );
}
