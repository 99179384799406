import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import queryString from 'query-string';
import { environment } from '../../coeffects/environment';
import { location } from '../../coeffects/location';
import { analytics } from '../../effects/analytics';
import {
  SELF_SERVICE_CREATE,
  SELF_SERVICE_ERROR,
} from '../../constants/routes';
import { navigateTo, redirectTo } from '../../effects/routing';
import { retrieveOfferName } from './retrieveOfferName';

export const SELFSERVICE_CREATE_SUBMITTED = 'SELFSERVICE_CREATE_SUBMITTED';
export const SELFSERVICE_CREATE_FAILED = 'SELFSERVICE_CREATE_FAILED';
export const SELFSERVICE_CREATED_SUCCESS = 'SELFSERVICE_CREATED_SUCCESS';
export const SELFSERVICE_ERROR_GO_BACK = 'SELFSERVICE_ERROR_GO_BACK';

registerEventHandler(
  SELFSERVICE_CREATE_SUBMITTED,
  (
    { environment: { apiUrl }, location: { queryString: receivedQueryString } },
    { email, companyName, phone }
  ) => {
    const phoneNumber = phone.dialCode + phone.phoneNumber;
    const { countryCode, plan } = queryString.parse(receivedQueryString);
    const payload = {
      email,
      companyName,
      phone: phoneNumber,
      country: countryCode,
      planId: plan,
      offerName: retrieveOfferName(plan),
    };

    const url = new URL(`${apiUrl}/self-service/publisher/create`);
    const origin = 'self-service-form';
    url.searchParams.append('email', email);
    url.searchParams.append('company', companyName);
    url.searchParams.append('phone', phoneNumber);
    url.searchParams.append('origin', origin);
    url.searchParams.append('country', countryCode);
    url.searchParams.append('planId', plan);

    return {
      ...analytics.trackClick(
        `self-service-create-form-submit-${countryCode}`,
        'self-service-create-form',
        payload
      ),
      ...http.get({
        url: url.toString(),
        successEvent: [SELFSERVICE_CREATED_SUCCESS, payload],
        errorEvent: [SELFSERVICE_CREATE_FAILED, payload],
      }),
    };
  },
  [environment(), location()]
);

registerEventHandler(SELFSERVICE_CREATED_SUCCESS, (_, [{ data }, payload]) => ({
  ...analytics.trackClick(
    `self-service-create-form-success-${payload.country}`,
    'self-service-create-form',
    payload
  ),
  ...redirectTo(data.link),
}));

registerEventHandler(SELFSERVICE_CREATE_FAILED, (_, [, payload]) => ({
  ...analytics.trackClick(
    `self-service-create-form-error-user-exist-${payload.country}`,
    'self-service-create-form',
    payload
  ),
  ...navigateTo(SELF_SERVICE_ERROR),
  ...state.set({
    selfService: {
      email: payload.email,
      country: payload.country,
      planId: payload.planId,
    },
  }),
}));

registerEventHandler(
  SELFSERVICE_ERROR_GO_BACK,
  ({ state: { countryCode, plan } }) => ({
    ...navigateTo(
      `${SELF_SERVICE_CREATE}?countryCode=${countryCode}&plan=${plan}`
    ),
  }),
  [
    state.get({
      countryCode: 'selfService.country',
      plan: 'selfService.planId',
    }),
  ]
);
