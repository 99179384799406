import Layout from 'design-system/components/Layout';
import styled from 'styled-components';

import { border, BORDER_SIZE } from 'design-system/styles/border';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import LegalLinks from '../../partials/LegalLinks/LegalLinks';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';
import SelfServiceCreateForm from './SelfServiceCreateForm';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  svg {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 464px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};

  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A10,
  })}
  ${spacing.inset(SPACING_SIZE.XL)}
  ${spacing.stack(SPACING_SIZE.XL)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`;

export default function SelfServiceCreate() {
  return (
    <Layout>
      <Layout.Content>
        <Wrapper>
          <Content>
            <Card>
              <BrandLogoForAnonymousUsers />
              <SelfServiceCreateForm />
            </Card>
            <Footer>
              <LegalLinks />
            </Footer>
          </Content>
        </Wrapper>
      </Layout.Content>
    </Layout>
  );
}
