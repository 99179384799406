import {
  registerGlobalBatteries,
  registerQueryParamsBatteries,
} from 'reffects-batteries';
import { registerStateBatteries, store } from 'reffects-store';
import { dispatch } from 'reffects';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import registerOptimizeImagesEffect from './effects/optimizeImages';
import httpClient from './infrastructure/httpClient';
import optimizer from './infrastructure/optimizer';
import cookieClient from './infrastructure/cookieClient';
import registerAppEvents from './App/events';
import registerToastEffect from './effects/toast';
import * as timer from './infrastructure/timer';
import registerPageReloadEffect from './effects/pageReload';
import registerFeatureFlagsCoeffectHandler from './coeffects/featureFlags';
import { registerCalendarCoeffectHandler } from './coeffects/calendar';
import dateBasedCalendar from './infrastructure/dateBasedCalendar';
import registerHttpEffects from './effects/http';
import { redirectToCanonical } from './utils/canonicalURLRedirection';
import registerAuthTokenCoeffect from './coeffects/authToken';
import registerAuthTokenEffects from './effects/authToken';
import registerLoadTranslationsEffect from './effects/loadTranslations';
import registerRenderAppEffects from './effects/renderApp';
import registerEnvironmentCoeffect from './coeffects/environment';
import registerUuidCoeffect from './coeffects/uuid';
import registerEntryPointEvents, { INITIALIZE_APP } from './events';
import registerDownloadFileEffect, {
  DOWNLOAD_CSV,
  DOWNLOAD_XLS,
} from './effects/downloadFile';
import { downloadCsv, parseJsonToCsv } from './infrastructure/csv';
import jwtParser from './utils/jwt';
import App from './App/App';
import { registerLocationCoeffect } from './coeffects/location';
import { registerQueryParamsAllCoeffect } from './coeffects/queryParamsAll';
import registerAnalyticsEffects from './effects/analytics';
import registerLinkTrackingEvents from './partials/Tracking/events';
import { createAnalyticsClient } from './infrastructure/analytics';
import { downloadXls, parseJsonToXls } from './infrastructure/xls';
import registerClipboardEffects from './effects/clipboard';
import registerLocalStorageEffects from './effects/localStorage';
import registerLocalStorageCoeffect from './coeffects/localStorage';
import registerHistoryCoeffect from './coeffects/history';
import { registerRandomNumberCoeffects } from './coeffects/randomNumber';

const initialState = {
  propertiesTable: {
    isLoading: true,
    rows: [],
    pagination: {},
    sorting: {},
  },
  unitsById: {},
  unitsTable: {
    isLoading: true,
    rows: [],
    pagination: {},
    sorting: {},
  },
  projectUnitsById: {},
  propertiesSummary: {
    isLoading: true,
  },
  'adForm:isInitialized': false,
  'companyProfile:contacts': {
    emails: { pagination: {} },
    phones: { pagination: {} },
    whatsapp: { pagination: {} },
    line: { pagination: {} },
    viber: { pagination: {} },
    facebook: { pagination: {} },
  },
  websiteBrand: window.websiteBrand,
  useStonly: window.useStonly,
  appDomain: window.location.origin,
  metaPixel: window.metaPixel,
};

store.initialize(initialState);

const authTokenCookieName = process.env.AUTH_TOKEN_COOKIE_NAME;
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

redirectToCanonical(window.location.href, (url) => {
  window.location.replace(url);
});

registerHttpEffects({
  dispatch,
  httpClient,
});
registerStateBatteries();
registerLoadTranslationsEffect();
registerRenderAppEffects(() => {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
});
registerClipboardEffects(window.navigator.clipboard);
registerPageReloadEffect(window);
registerQueryParamsBatteries(window);
registerQueryParamsAllCoeffect(window);
registerLocalStorageEffects(window);
registerCalendarCoeffectHandler(dateBasedCalendar);
registerAuthTokenEffects(cookieClient, authTokenCookieName);
registerDownloadFileEffect(parseJsonToCsv, downloadCsv, DOWNLOAD_CSV);
registerDownloadFileEffect(parseJsonToXls, downloadXls, DOWNLOAD_XLS);
registerAuthTokenCoeffect(cookieClient, authTokenCookieName, jwtParser);
registerEnvironmentCoeffect({
  API_PORT: process.env.API_PORT,
  ORIGIN: window.location.origin,
  PAYMENTS_API_URL: process.env.PAYMENTS_API_URL,
  CHAT_TRACKING_API_URL: process.env.CHAT_TRACKING_API_URL,
  DISABLE_LOGGED_PUBLISHER_CHECKING:
    process.env.DISABLE_LOGGED_PUBLISHER_CHECKING,
  // eslint-disable-next-line no-undef
  VERSION: __webpack_hash__,
});
registerUuidCoeffect();
registerLocationCoeffect();
registerRandomNumberCoeffects();
registerGlobalBatteries();

registerToastEffect(store, timer);
registerAnalyticsEffects(
  createAnalyticsClient(() => window.analytics),
  store,
  process.env.DISABLE_TRACKING
);

registerOptimizeImagesEffect(optimizer);
registerAppEvents();
registerLinkTrackingEvents();
registerFeatureFlagsCoeffectHandler(store);
registerLocalStorageCoeffect(window);
registerHistoryCoeffect();

registerEntryPointEvents();

// eslint-disable-next-line no-underscore-dangle
window._hjSettings = { hjid: process.env.HOTJAR_ID, hjsv: 6 };

dispatch(INITIALIZE_APP);
